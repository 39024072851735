import { Route, Routes } from 'react-router-dom';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import Login from '../views/login/Login';
import Locations from '../views/locations/Locations';
import Categories from '../views/categories/Categories';
import Users from '../views/users/Users';
import Analytics from '../views/analytics/Analytics';
import Generator from '../views/generator/Generator';
import PrivacyPolicies from '../views/privacy_policies/PrivacyPolicies';

const Router = () => {
  const token = sessionStorage.getItem('token');
  const root = sessionStorage.getItem('root') == '1' ? true : false;
  const admin = sessionStorage.getItem('admin') == '1' ? true : false;

  return (
    <>
      {window.location.pathname == '/privacy_policies' ? (
        <Routes>
          <Route path={'/privacy_policies'} element={<PrivacyPolicies />} />
        </Routes>
      ) : !token ? (
        <Login />
      ) : (
        <div style={{ overflowY: 'hidden' }}>
          <Header />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Sidebar />
            <div style={{ width: '80vw', height: '90vh', overflowY: 'auto' }}>
              <Routes>
                <Route path={'/'} element={<Locations />} />
                <Route path={'/locations'} element={<Locations />} />
                {root && (
                  <Route path={'/categories'} element={<Categories />} />
                )}
                {admin && <Route path={'/users'} element={<Users />} />}
                {root && <Route path={'/analytics'} element={<Analytics />} />}
                {root && <Route path={'/generator'} element={<Generator />} />}
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Router;
