import { useState, useEffect, useContext } from 'react';
import { TextField, Switch, Autocomplete } from '@mui/material';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../../components/modal-form/ModalForm';
import Map from './Map';

const LocationsModal = (props) => {
  const { open, setOpen, action, setAction, rowSelected, fetchData } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    headerTitle: '',
    name: '',
    category: null,
    subcategory: null,
    description: '',
    url: '',
    active: false,
  };

  const initialMapCenter = {
    lat: -31.728355371265835,
    lng: -65.00566305532926,
  };

  const [modalData, setModalData] = useState(initialModalData);
  const [mapCenter, setMapCenter] = useState(initialMapCenter);
  const [markers, setMarkers] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const handleMapClick = (event) => {
    let newMarker = {
      id: 0,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setMarkers([newMarker]);
  };

  const resetForm = () => {
    setAction('');
    setModalData(initialModalData);
    setShowLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setModalData({
      ...modalData,
      [event.target.id]: event.target.value,
    });
  };

  const handleCategoryChange = (event, newValue) => {
    setModalData({ ...modalData, category: newValue, subcategory: null });

    let options = newValue.childs.map((cat) => ({
      value: cat.id,
      label: cat.name,
    }));

    setSubcategoryOptions(options);
  };

  const fetchCategories = () => {
    setShowLoading(true);

    let url = 'category/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        let options = response.data.map((cat) => ({
          value: cat.id,
          label: cat.name,
          childs: cat.childs,
        }));

        setCategoryOptions(options);

        if (action == 'edit') {
          let categorySelected = options.find(
            (option) => option.label == rowSelected.category
          );

          let subOptions = categorySelected.childs.map((cat) => ({
            value: cat.id,
            label: cat.name,
          }));

          setSubcategoryOptions(subOptions);
        }
      }

      setShowLoading(false);
    });
  };

  const handleSubmit = () => {
    if (modalData.name == '' || !modalData.category) {
      setError('Debe completar todos los campos.');
      return;
    }

    if (
      modalData.category?.childs?.length > 0 &&
      modalData.subcategory == null
    ) {
      setError('Debe seleccionar una subcategoría.');
      return;
    }

    if (markers.length < 1) {
      setError('Debe seleccionar un marcador en el mapa.');
      return;
    }

    let parsedUrl = '';
    if (modalData.url != '') {
      if (!modalData.url.includes('http')) {
        parsedUrl = 'https://' + modalData.url;
      }

      if (parsedUrl.includes('www.')) {
        parsedUrl = parsedUrl.replace('www.', '');
      }
    }

    let categoryId = null;
    if (modalData.subcategory != null) {
      if (modalData.subcategory.value) {
        categoryId = modalData.subcategory.value;
      } else {
        let category = subcategoryOptions.find(
          (subcategory) => subcategory.label == modalData.subcategory
        );
        categoryId = category.value;
      }
    } else {
      if (modalData.category.value) {
        categoryId = modalData.category.value;
      } else {
        let category = categoryOptions.find(
          (category) => category.label == modalData.category
        );
        categoryId = category.value;
      }
    }

    setShowLoading(true);

    let url = `location/${action == 'edit' ? 'update' : 'create'}`;
    let params = {
      name: modalData.name,
      category_id: categoryId,
      description: modalData.description,
      url: parsedUrl,
      active: modalData.active ? 1 : 0,
      latitude: markers[0].lat,
      longitude: markers[0].lng,
    };

    if (action == 'edit') {
      params.id = rowSelected.location_id;
    }

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        resetForm();
        fetchData();
        return setSuccess(
          `La locación ha sido ${
            action == 'edit' ? 'editada' : 'creada'
          } exitosamente.`
        );
      } else {
        resetForm();
        return setError(
          `La ${
            action == 'edit' ? 'edición' : 'creación'
          } de la locación ha fallado.`
        );
      }
    });
  };

  useEffect(() => {
    fetchCategories();

    if (action == 'edit') {
      setModalData({
        headerTitle: 'Editar sitio',
        name: rowSelected.name,
        category: rowSelected.category,
        subcategory: rowSelected.subcategory,
        description: rowSelected.description,
        url: rowSelected.url,
        active: rowSelected.active,
      });

      setMarkers([
        {
          id: 0,
          lat: Number(rowSelected.latitude),
          lng: Number(rowSelected.longitude),
        },
      ]);

      setMapCenter({
        lat: Number(rowSelected.latitude),
        lng: Number(rowSelected.longitude),
      });
    } else {
      setModalData({
        headerTitle: 'Agregar sitio',
        name: '',
        category: '',
        subcategory: '',
        description: '',
        url: '',
        active: false,
      });

      setMarkers([]);

      setMapCenter(initialMapCenter);
    }
  }, [action]);

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={modalData.headerTitle}
      onModalSubmit={handleSubmit}>
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <TextField
            id='name'
            label='Nombre'
            value={modalData.name}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
          <Autocomplete
            id='category'
            options={categoryOptions}
            value={modalData.category}
            onChange={handleCategoryChange}
            style={{ flex: 1, margin: 10 }}
            renderInput={(params) => (
              <TextField {...params} label='Categoría' />
            )}
            isOptionEqualToValue={(option, selected) =>
              option.label == selected || option.label == selected.label
            }
          />
          <Autocomplete
            id='subcategory'
            options={subcategoryOptions}
            value={modalData.subcategory}
            onChange={(event, newValue) =>
              setModalData({ ...modalData, subcategory: newValue })
            }
            style={{ flex: 1, margin: 10 }}
            renderInput={(params) => (
              <TextField {...params} label='Subcategoría' />
            )}
            isOptionEqualToValue={(option, selected) =>
              option.label == selected || option.label == selected.label
            }
          />
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <TextField
            id='description'
            label='Descripción (opcional)'
            value={modalData.description}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            multiline={true}
          />
          <TextField
            id='url'
            label='URL Web (opcional)'
            value={modalData.url}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            title='Formato: www.minaclavero.gov.ar o www.instagram.com/turismominaclavero'
          />
          <div style={{ padding: 10 }}>
            ¿Activo?
            <Switch
              checked={modalData.active}
              onChange={(event) => {
                setModalData((prevState) => ({
                  ...prevState,
                  active: event.target.checked,
                }));
              }}
            />
          </div>
        </div>

        <div style={{ padding: 10 }}>
          <Map
            markers={markers}
            handleMapClick={handleMapClick}
            mapCenter={mapCenter}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default LocationsModal;
