import { useState, useContext } from 'react';
import { TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Background from '../../assets/background-login.jpg';

const Login = () => {
  const { setError, setShowLoading } = useContext(Context);

  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value.trim(),
    }));
  };

  const handleSubmit = () => {
    if (userData.username == '' || userData.password == '') {
      setError('Debe completar ambos campos.');
      return;
    }

    setShowLoading(true);

    let url = 'login';
    let params = userData;

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('admin', response.data.admin);
        sessionStorage.setItem('root', response.data.root);
        sessionStorage.setItem('username', userData.username);
        sessionStorage.setItem('ttl', response.data.ttl);
        window.location.href = '/';
        setShowLoading(false);
      } else {
        setShowLoading(false);
        return setError('Credenciales inválidas.');
      }
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.cardTitle}>Iniciar sesión</div>
        <div style={styles.cardBody}>
          <TextField
            id='username'
            label='Usuario/a'
            color='secondary'
            style={styles.input}
            autoComplete='off'
            onChange={handleChange}
          />
          <TextField
            id='password'
            label='Contraseña'
            color='secondary'
            style={styles.inputPassword}
            autoComplete='off'
            type={showPassword ? 'text' : 'password'}
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                handleSubmit();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant='contained'
            color='secondary'
            sx={styles.button}
            onClick={handleSubmit}>
            Ingresar
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    contain: 'content',
    display: 'flex',
    flex: 1,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffffd7',
    width: '50vw',
    height: '70vh',
    borderRadius: 15,
    boxShadow: '0vh 0vh 5vh rgba(41, 17, 0, 0.3)',
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 30,
    marginBottom: 20,
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  input: {
    flex: 1,
    margin: 10,
    minWidth: 270,
  },
  inputPassword: {
    flex: 1,
    margin: 10,
    fontFamily: 'password',
  },
  button: {
    backgroundColor: '#272727',
    color: '#CBCEB2',
    marginTop: 2,
  },
};

export default Login;
