import { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Context } from '../../context/Context';
import PasswordModal from './PasswordModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../../assets/logo2-white.png';
import { logout } from '../../utils/helpers';

const Header = () => {
  const { setShowLoading } = useContext(Context);

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const username = sessionStorage.getItem('username');

  const styles = {
    container: {
      height: '10vh',
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      height: '8vh',
      marginLeft: 10,
    },
    text: {
      color: '#ffffff',
    },
  };

  return (
    <div style={styles.container}>
      <img src={Logo} title='Home' alt='logo' style={styles.logo} />
      <div style={styles.text}> Mina Clavero. Córdoba. Argentina. </div>
      <div style={{ color: '#ffffff' }}>
        <Button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color: '#ffffff',
            marginRight: 1,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            },
          }}>
          {username}
          {Boolean(anchorEl) ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => setShowPasswordModal(true)}>
            <ListItemIcon>
              <KeyIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Cambiar contraseña</ListItemText>
          </MenuItem>
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Cerrar sesión</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      <PasswordModal
        open={showPasswordModal}
        setOpen={setShowPasswordModal}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
};

export default Header;
