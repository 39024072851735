import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';
import { ContextProvider } from './context/Context';
import Router from './router/Router';
import Alerts from './components/alerts/Alerts';
import Loading from './components/loading/Loading';
import { logout } from './utils/helpers';

const App = () => {
  const [counter, setCounter] = useState(0);

  const ttl = sessionStorage.getItem('ttl');

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#DD5394',
        },
        secondary: {
          main: '#4C518B',
        },
      },
      components: {
        MuiTablePagination: {
          styleOverrides: {
            selectLabel: {
              marginTop: 'revert',
            },
            displayedRows: {
              marginTop: 'revert',
            },
          },
        },
      },
    },
    esES
  );

  // useEffect(() => {
  //   const sessionInterval = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(sessionInterval);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (counter == ttl) {
  //     let token = sessionStorage.getItem('token');

  //     if (token && token != '') {
  //       logout();
  //     }
  //   }
  // }, [counter]);

  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <Router />
        <Alerts />
        <Loading />
      </ContextProvider>
    </ThemeProvider>
  );
};

export default App;
