import { useEffect, useState, useContext } from 'react';
import { Switch, IconButton } from '@mui/material';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import LocationsModal from './LocationsModal';
import LocationsMap from './LocationsMap';

const Locations = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const [rowSelected, setRowSelected] = useState({});
  const [deleteLocation, setDeleteLocation] = useState(false);
  const [activeLocation, setActiveLocation] = useState(false);
  const [action, setAction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
    },
    {
      field: 'category',
      headerName: 'Categoría',
    },
    {
      field: 'subcategory',
      headerName: 'Subcategoría',
      onRenderCell: (row) => (
        <div title={row.subcategory}>
          {!row.subcategory || row.subcategory == '' ? '-' : row.subcategory}
        </div>
      ),
    },
    {
      field: 'url',
      headerName: 'URL Web',
      onRenderCell: (row) => (
        <div title={row.url}>{row.url == '' ? '-' : row.url}</div>
      ),
    },
    {
      field: 'active',
      headerName: 'Activo',
      onRenderCell: (row) => (
        <Switch
          checked={row.active == '1'}
          onChange={() => {
            setActiveLocation(true);
            setRowSelected(row);
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      actions: [
        {
          icon: <EditIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setAction('edit');
            setOpenModal(true);
          },
          tooltip: 'Editar',
        },
        {
          icon: <DeleteIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setDeleteLocation(true);
          },
          tooltip: 'Eliminar',
        },
      ],
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'location/list';
    let params = {
      all: 1,
    };

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        let row = response.data.map((data) => ({
          location_id: data.location_id,
          name: data.name,
          category: data.category,
          subcategory: data.subcategory,
          url: data.url.replace('https://', 'www.'),
          active: data.active == '1' ? true : false,
          description: data.description,
          latitude: data.latitude,
          longitude: data.longitude,
        }));

        setRows(row);
      }

      setShowLoading(false);
    });
  };

  const handleDeleteLocation = () => {
    setShowLoading(true);

    let url = 'location/delete';
    let params = {
      id: rowSelected.location_id,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setDeleteLocation(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setShowLoading(false);
        setDeleteLocation(false);
        fetchData();
        return setSuccess('La locación ha sido eliminada exitosamente.');
      } else {
        setShowLoading(false);
        setDeleteLocation(false);
        return setError('La eliminación de la locación ha fallado.');
      }
    });
  };

  const handleActiveLocation = () => {
    setShowLoading(true);

    let url = 'location/active';
    let params = {
      id: rowSelected.location_id,
      active: !rowSelected.active,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setActiveLocation(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setShowLoading(false);
        setActiveLocation(false);
        fetchData();
        return setSuccess('La locación ha sido activada exitosamente.');
      } else {
        setShowLoading(false);
        setActiveLocation(false);
        return setError('La activación de la locación ha fallado.');
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <h2> Locaciones </h2>
        {/* Funcionalidad de vista de mapa de locaciones */}
        {/* <IconButton onClick={() => setOpenMap(true)} title='Ver mapa'>
          <PlaceIcon fontSize='medium' color='secondary' />
        </IconButton> */}
      </div>
      <Grid
        columns={columns}
        rows={rows}
        onAddButtonClick={() => {
          setAction('add');
          setOpenModal(true);
        }}
      />

      {openModal && (
        <LocationsModal
          open={openModal}
          setOpen={setOpenModal}
          action={action}
          setAction={setAction}
          rowSelected={rowSelected}
          fetchData={fetchData}
        />
      )}

      {openMap && (
        <LocationsMap open={openMap} setOpen={setOpenMap} rows={rows} />
      )}

      <DialogConfirm
        open={deleteLocation || activeLocation}
        setOpen={deleteLocation ? setDeleteLocation : setActiveLocation}
        description={
          deleteLocation
            ? '¿Desea eliminar la locación?'
            : `¿Desea ${
                rowSelected.active ? 'desactivar' : 'activar'
              } la locación?`
        }
        onConfirm={deleteLocation ? handleDeleteLocation : handleActiveLocation}
      />
    </div>
  );
};

export default Locations;
