import { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import AddCategories from './AddCategories';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Categories = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState({});

  const theme = useTheme();

  const fetchData = () => {
    setShowLoading(true);

    let url = 'category/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setCategories(response.data);

        let options = response.data.map((cat) => ({
          value: cat.id,
          label: cat.name,
        }));

        setCategoryOptions(options);
      }

      setShowLoading(false);
    });
  };

  const handleDeleteCategory = () => {
    setShowLoading(true);

    let url = 'category/delete';
    let params = {
      id: categorySelected.id,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setDeleteCategory(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setShowLoading(false);
        setDeleteCategory(false);
        fetchData();
        return setSuccess('La categoría ha sido eliminada exitosamente.');
      } else {
        setShowLoading(false);
        setDeleteCategory(false);
        return setError('La eliminación de la categoría ha fallado.');
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Categorías </h2>
      <AddCategories categoryOptions={categoryOptions} fetchData={fetchData} />

      {categories.length > 0 && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 30,
            marginTop: 30,
            marginBottom: 20,
          }}>
          {categories.map((category) => {
            return (
              <Card
                key={category.id}
                sx={{
                  minHeight: 170,
                  transition: 'transform .3s',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform .3s',
                  },
                }}
                raised>
                <CardContent>
                  <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    {category.name}
                    {category.childs.length == 0 && (
                      <IconButton
                        style={{ marginBottom: 4 }}
                        onClick={() => {
                          setCategorySelected(category);
                          setDeleteCategory(true);
                        }}>
                        <DeleteOutlineIcon fontSize='small' color='primary' />
                      </IconButton>
                    )}
                  </div>
                  {category.childs.length == 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: theme.palette.primary.main,
                      }}>
                      No hay subcategorías.
                    </div>
                  ) : (
                    category.childs.map((child) => {
                      return (
                        <div
                          key={child.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <Typography color='text.secondary'>
                            - {child.name}
                          </Typography>
                          <IconButton
                            style={{ marginBottom: 3 }}
                            onClick={() => {
                              setCategorySelected(child);
                              setDeleteCategory(true);
                            }}>
                            <DeleteOutlineIcon
                              fontSize='small'
                              color='primary'
                            />
                          </IconButton>
                        </div>
                      );
                    })
                  )}
                </CardContent>
              </Card>
            );
          })}
        </div>
      )}

      <DialogConfirm
        open={deleteCategory}
        setOpen={setDeleteCategory}
        description={'¿Desea eliminar la categoría?'}
        onConfirm={handleDeleteCategory}
      />
    </div>
  );
};

export default Categories;
