import { useState, useEffect, useContext } from 'react';
import { TextField, Switch, InputAdornment, IconButton } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../../components/modal-form/ModalForm';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UsersModal = (props) => {
  const { open, setOpen, action, setAction, rowSelected, fetchData } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    headerTitle: '',
    username: '',
    password: '',
    password_repeat: '',
    name: '',
    lastname: '',
    email: '',
    admin: false,
  };

  const [modalData, setModalData] = useState(initialModalData);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const resetForm = () => {
    setAction('');
    setModalData(initialModalData);
    setShowLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setModalData({
      ...modalData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = () => {
    for (const field in modalData) {
      if (
        action == 'edit' &&
        (field == 'password' || field == 'password_repeat')
      ) {
        continue;
      }

      if (modalData[field] == '' && field != 'email' && field != 'admin') {
        setError('Debe completar todos los campos.');
        return;
      }
    }

    if (action == 'add') {
      if (modalData.password.length < 8) {
        setError('La contraseña debe tener al menos 8 caracteres de longitud.');
        return;
      }

      if (modalData.password != modalData.password_repeat) {
        setError('Las contraseñas no coinciden.');
        return;
      }
    }

    setShowLoading(true);

    let url = `user/${action == 'edit' ? 'update' : 'create'}`;
    let params = {
      firstname: modalData.name,
      lastname: modalData.lastname,
      email: modalData.email,
      username: modalData.username,
      admin: modalData.admin,
    };

    if (action == 'edit') {
      params.id = rowSelected.user_id;
    } else {
      params.password = modalData.password;
    }

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        resetForm();
        fetchData();
        return setSuccess(
          `El usuario ha sido ${
            action == 'edit' ? 'editado' : 'creado'
          } exitosamente.`
        );
      } else {
        resetForm();
        return setError(
          `La ${
            action == 'edit' ? 'edición' : 'creación'
          } de usuario ha fallado.`
        );
      }
    });
  };

  useEffect(() => {
    if (action == 'edit') {
      setModalData({
        headerTitle: 'Editar usuario',
        username: rowSelected.username,
        password: rowSelected.password ?? '',
        password_repeat: rowSelected.password ?? '',
        name: rowSelected.name,
        lastname: rowSelected.lastname,
        email: rowSelected.email,
        admin: rowSelected.admin,
      });
    } else {
      setModalData({
        headerTitle: 'Agregar usuario',
        username: '',
        password: '',
        password_repeat: '',
        name: '',
        lastname: '',
        email: '',
        admin: false,
      });
    }
  }, [action]);

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={modalData.headerTitle}
      onModalSubmit={handleSubmit}>
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <TextField
            id='name'
            label='Nombre'
            color='primary'
            value={modalData.name}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
          <TextField
            id='lastname'
            label='Apellido'
            color='primary'
            value={modalData.lastname}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
          <TextField
            id='email'
            label='Email (opcional)'
            color='primary'
            value={modalData.email}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          {action != 'edit' && (
            <>
              <TextField
                id='username'
                label='Nombre de usuario'
                color='primary'
                value={modalData.username}
                onChange={handleChange}
                style={{ flex: 1, margin: 10 }}
                autoComplete='off'
              />
              <TextField
                id='password'
                label='Contraseña'
                color='primary'
                value={modalData.password}
                onChange={handleChange}
                style={{ flex: 1, margin: 10 }}
                autoComplete='off'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id='password_repeat'
                label='Repetir contraseña'
                color='primary'
                value={modalData.password_repeat}
                onChange={handleChange}
                style={{ flex: 1, margin: 10 }}
                autoComplete='off'
                type={showPasswordRepeat ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setShowPasswordRepeat(!showPasswordRepeat)
                        }
                        edge='end'>
                        {showPasswordRepeat ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </div>
        <div style={{ padding: 10 }}>
          ¿Puede gestionar usuarios?
          <Switch
            checked={modalData.admin}
            onChange={(event) => {
              setModalData((prevState) => ({
                ...prevState,
                admin: event.target.checked,
              }));
            }}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default UsersModal;
