import { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

const Map = (props) => {
  const {
    markers,
    handleMapClick,
    mapCenter,
    showMarkerData = false,
    height = 270,
  } = props;

  const [selectedMarker, setSelectedMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCc-141YlJYFFy05MG0Qvn7ocF6DrMwSs8',
  });

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: height }}
        center={mapCenter}
        zoom={15}
        onClick={(event) => handleMapClick(event)}
        options={{
          mapTypeControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}>
        {markers.map((marker) => (
          <Marker
            key={marker.id}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => setSelectedMarker(marker)}
          />
        ))}

        {showMarkerData && selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={() => setSelectedMarker(null)}>
            <div>{selectedMarker.name}</div>
          </InfoWindow>
        )}
      </GoogleMap>
    )
  );
};

export default Map;
