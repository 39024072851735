import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

const Grid = (props) => {
  const {
    rows = [],
    columns = [],
    orderBy = null,
    rowsPerPage = 10,
    rowsPerPageOptions = [5, 10, 25],
    disableAddMenu = false,
    onAddButtonClick = null,
    extraButtons = [],
  } = props;

  const [menuButtons, setMenuButtons] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize:
      rowsPerPage && rowsPerPageOptions.includes(rowsPerPage)
        ? rowsPerPage
        : rowsPerPageOptions[0],
    page: 0,
  });

  const [tableData, setTableData] = useState({
    tableRows: [],
    tableColumns: [],
  });

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          marginBottom: 5,
          marginTop: 5,
          marginLeft: 5,
          color: 'black',
        }}>
        {menuButtons.map((button, index) => (
          <ExtraButton button={button} key={index} />
        ))}
        <GridToolbarFilterButton style={{ marginRight: 15 }} />
        <GridToolbarColumnsButton style={{ marginRight: 15 }} />
        <GridToolbarDensitySelector style={{ marginRight: 15 }} />
        <div style={{ position: 'absolute', right: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GridToolbarQuickFilter />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    // Set table menu extra buttons
    let buttons = [];

    if (!disableAddMenu) {
      buttons.push({
        icon: <AddIcon />,
        label: 'Agregar',
        onClick: onAddButtonClick ? onAddButtonClick : null,
      });
    }

    if (extraButtons.length > 0) {
      extraButtons.forEach((button) => {
        buttons.push(button);
      });
    }

    setMenuButtons(buttons);

    // Fill the table with data
    let tableRows = [];
    let tableColumns = [];

    rows?.forEach((row, index) => {
      row.id = index;
      tableRows.push(row);
    });

    columns?.forEach((column) => {
      let field = column.field
        ? column.field
        : column.headerName.toLowerCase().replace(' ', '_');
      let type = column.type ? column.type : 'string';
      let align = column.align ? column.align : 'center';
      let minWidth = column.flex ? 60 : column.headerName.length * 10;
      let flex = column.flex ? column.flex : 1;

      let column_structure = {
        field: field,
        headerName: column.headerName,
        type: type,
        headerAlign: 'center',
        align: align,
        minWidth: minWidth,
        flex: flex,
      };

      if (column.onRenderCell) {
        column_structure.renderCell = (params) =>
          column.onRenderCell(params.row);
      }

      if (column.type === 'actions') {
        column_structure.getActions = (params) => {
          return column.actions.map((action) => (
            <GridActionsCellItem
              id={params.row.id}
              icon={action.icon}
              onClick={action.onClick ? () => action.onClick(params.row) : null}
              showInMenu={action.showInMenu ? action.showInMenu : false}
              label={action.label ? action.label : ''}
              disabled={action.disabled ? action.disabled(params.row) : false}
              title={action.tooltip ? action.tooltip : ''}
            />
          ));
        };
      }

      tableColumns.push(column_structure);
    });

    setTableData({
      tableRows: tableRows,
      tableColumns: tableColumns,
    });
  }, [rows, columns]);

  useEffect(() => {
    if (orderBy) {
      setSortModel([orderBy]);
    }
  }, [orderBy]);

  return (
    <DataGrid
      columns={tableData.tableColumns}
      rows={tableData.tableRows}
      pageSizeOptions={rowsPerPageOptions}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      autoHeight
      density={'compact'}
      disableColumnMenu
      slots={{ toolbar: CustomToolbar }}
    />
  );
};

const ExtraButton = (props) => {
  const { button } = props;

  const [hovered, setHovered] = useState(false);

  const theme = useTheme();

  const muiButtonStyles = {
    marginRight: 15,
    display: 'inline-flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: hovered ? 'rgba(221, 83, 148, 0.04)' : 'transparent',
    outline: 0,
    border: 0,
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    WebkitAppearance: 'none',
    textDecoration: 'none',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    minWidth: '64px',
    padding: '4px 5px',
    borderRadius: '4px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.primary.main,
    textRendering: 'auto',
    wordSpacing: 'normal',
    textIndent: '0px',
    textShadow: 'none',
    textAlign: 'center',
    paddingInline: '6px',
    WebkitFontSmoothing: 'antialiased',
  };

  const muiSpanStyles = {
    display: 'inherit',
    marginRight: '8px',
    marginLeft: '-2px',
    WebkitTapHighlightColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    textRendering: 'auto',
    wordSpacing: 'normal',
    textIndent: '0px',
    textShadow: 'none',
    textAlign: 'center',
    WebkitFontSmoothing: 'antialiased',
  };

  return (
    <button
      tabIndex='0'
      type='button'
      aria-label='button'
      aria-haspopup='menu'
      aria-labelledby='mui-184'
      id='mui-183'
      style={muiButtonStyles}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={button.onClick ? button.onClick : null}>
      <span style={muiSpanStyles}>{button.icon}</span>
      {button.label}
      <span className='MuiTouchRipple-root css-w0pj6f'></span>
    </button>
  );
};

export default Grid;
