import { useState, useEffect } from 'react';
import ModalForm from '../../components/modal-form/ModalForm';
import Map from './Map';

const LocationsMap = (props) => {
  const { open, setOpen, rows } = props;

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    let data = rows.map((row) => ({
      id: row.location_id,
      name: row.name,
      url: row.url,
      description: row.description,
      lat: Number(row.latitude),
      lng: Number(row.longitude),
    }));

    setMarkers(data);
  }, []);

  return (
    <ModalForm
      open={open}
      resetForm={() => setOpen(false)}
      headerTitle={'Mapa'}
      footerButtons={false}>
      <div style={{ paddingBottom: 20 }}>
        <Map
          markers={markers}
          handleMapClick={() => {}}
          mapCenter={{
            lat: -31.728355371265835,
            lng: -65.00566305532926,
          }}
          showMarkerData={true}
          height={500}
        />
      </div>
    </ModalForm>
  );
};

export default LocationsMap;
