import { useState, useRef, useContext } from 'react';
import { Paper, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import { Context } from '../../context/Context';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';

const Generator = () => {
  const { setError } = useContext(Context);

  const theme = useTheme();

  const qrCodeRef = useRef(null);

  const [value, setValue] = useState('');
  const [url, setUrl] = useState('');
  const [ready, setReady] = useState(false);

  const handleGenerate = () => {
    if (url && url.trim() != '') {
      setValue(url.trim());
      setReady(true);
    } else {
      setValue('');
      setReady(false);
      setError('Debe completar el campo URL.');
    }
  };

  const handleDownload = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'qrcode.png';
        downloadLink.click();
      } catch {
        setError('La descarga ha fallado.');
      }
    }
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Generador de código QR </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 10,
          alignItems: 'center',
        }}>
        <TextField
          id='url'
          label='Ingrese URL'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          autoComplete='off'
        />
        <div>
          <Button
            variant='contained'
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: '#ffffff',
              width: 135,
              marginRight: 1,
            }}
            title='Generar'
            onClick={handleGenerate}>
            <QrCodeIcon style={{ marginRight: 2 }} />
            Generar
          </Button>
          {ready && (
            <Button
              variant='contained'
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: '#ffffff',
                width: 135,
              }}
              title='Descargar imagen'
              onClick={handleDownload}>
              <DownloadIcon style={{ marginRight: 2 }} />
              Descargar
            </Button>
          )}
        </div>
        {ready && (
          <Paper
            elevation={0}
            variant='outlined'
            style={{ marginTop: 10, height: '60vh' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#ffffff',
              }}
              ref={qrCodeRef}>
              <QRCode value={value} level='H' />
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Generator;
