import { useEffect, useState, useContext } from 'react';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UsersModal from './UsersModal';

const Users = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const [rowSelected, setRowSelected] = useState({});
  const [deleteUser, setDeleteUser] = useState(false);
  const [action, setAction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
    },
    {
      field: 'lastname',
      headerName: 'Apellido',
    },
    {
      field: 'username',
      headerName: 'Nombre de usuario',
    },
    {
      field: 'email',
      headerName: 'Email',
      onRenderCell: (row) => (row.email == '' ? '-' : row.email),
    },
    {
      field: 'admin',
      headerName: 'Gestiona usuarios',
      onRenderCell: (row) => (row.admin == true ? 'Si' : 'No'),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      actions: [
        {
          icon: <EditIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setAction('edit');
            setOpenModal(true);
          },
          tooltip: 'Editar',
        },
        {
          icon: <DeleteIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setDeleteUser(true);
          },
          tooltip: 'Eliminar',
        },
      ],
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'user/list';
    let params = {
      all: 1,
    };

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        let row = response.data.rows.map((data) => ({
          user_id: data.id,
          name: data.firstname,
          lastname: data.lastname,
          email: data.email,
          username: data.username,
          admin: data.admin == '1' ? true : false,
        }));

        setRows(row);
      }

      setShowLoading(false);
    });
  };

  const handleDeleteUser = () => {
    setShowLoading(true);

    let url = 'user/delete';
    let params = {
      id: rowSelected.user_id,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setDeleteUser(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setShowLoading(false);
        setDeleteUser(false);
        fetchData();
        return setSuccess('El usuario ha sido eliminado exitosamente.');
      } else {
        setShowLoading(false);
        setDeleteUser(false);
        return setError('La eliminación de usuario ha fallado.');
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Usuarios </h2>
      <Grid
        columns={columns}
        rows={rows}
        onAddButtonClick={() => {
          setAction('add');
          setOpenModal(true);
        }}
      />

      <UsersModal
        open={openModal}
        setOpen={setOpenModal}
        action={action}
        setAction={setAction}
        rowSelected={rowSelected}
        fetchData={fetchData}
      />

      <DialogConfirm
        open={deleteUser}
        setOpen={setDeleteUser}
        description='¿Desea eliminar el usuario?'
        onConfirm={handleDeleteUser}
      />
    </div>
  );
};

export default Users;
