const PrivacyPolicies = () => {
  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 30,
      }}>
      <h3>POLÍTICA DE PRIVACIDAD</h3>
      <div>
        Esta <strong>Política de Privacidad</strong> describe cómo la compañía
        recopila, utiliza y comparte información personal cuando utilizas
        nuestros servicios en línea y cualquier otro servicio relacionado (en
        adelante, los "Servicios"). Al acceder y utilizar nuestros Servicios,
        aceptas los términos y condiciones de esta Política de Privacidad.
      </div>
      <h4>1. Información que NO recopilamos</h4>
      <div>
        En nuestra compañía, valoramos la privacidad de nuestros usuarios y
        queremos dejar claro que no recopilamos deliberadamente ninguna
        información personal identificable de los usuarios, a menos que sea
        proporcionada voluntariamente por ellos. No recopilamos información como
        nombres, direcciones de correo electrónico, direcciones físicas u otra
        información personal similar.
      </div>
      <h4>2. Información recopilada de forma automática</h4>
      <div>
        No utilizamos herramientas de recopilación automática de datos, como
        cookies, balizas web u otras tecnologías similares, para recopilar
        información sobre los usuarios de manera no voluntaria.
      </div>
      <h4>3. Uso de la información</h4>
      <div>
        Dado que no recopilamos información personal identificable, no
        utilizamos ninguna información de los usuarios con fines comerciales, de
        marketing o cualquier otro propósito.
      </div>
      <h4>4. Terceros</h4>
      <div>
        No compartimos ninguna información personal identificable con terceros,
        ya que no recopilamos dicha información.
      </div>
      <h4>5. Seguridad</h4>
      <div>
        Aunque no recopilamos información personal identificable, tomamos
        medidas para proteger cualquier información que los usuarios puedan
        proporcionar de manera voluntaria. Utilizamos medidas de seguridad
        estándar de la industria para garantizar la integridad y la
        confidencialidad de la información que recibimos.
      </div>
      <h4>6. Cambios en la Política de Privacidad</h4>
      <div>
        Podemos actualizar esta Política de Privacidad ocasionalmente. Te
        notificaremos sobre cualquier cambio significativo mediante la
        publicación de la nueva Política de Privacidad en nuestro sitio web.
      </div>
      <h4>7. Contacto</h4>
      <div>
        Si tienes alguna pregunta sobre esta Política de Privacidad, puedes
        ponerte en contacto con nosotros por email a nmartinez.dev@gmail.com.
      </div>
    </div>
  );
};

export default PrivacyPolicies;
