import { useState, useContext } from 'react';
import {
  Paper,
  TextField,
  Button,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AddCategories = (props) => {
  const { categoryOptions, fetchData } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialData = {
    category: '',
    categoryParent: null,
    subcategory: '',
  };

  const [data, setData] = useState(initialData);

  const theme = useTheme();

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmitCategory = () => {
    if (data.category == '') {
      setError('Debe completar el campo categoría.');
      return;
    }

    setShowLoading(true);

    let url = 'category/create';
    let params = {
      name: data.category,
      parent_id: 0,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setData(initialData);
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setData(initialData);
        setShowLoading(false);
        fetchData();
        return setSuccess('La categoría ha sido creada exitosamente.');
      } else {
        setData(initialData);
        setShowLoading(false);
        return setError('La creación de la categoría ha fallado.');
      }
    });
  };

  const handleSubmitSubcategory = () => {
    if (!data.categoryParent || data.subcategory == '') {
      setError('Debe completar los campos.');
      return;
    }

    setShowLoading(true);

    let url = 'category/create';
    let params = {
      name: data.subcategory,
      parent_id: data.categoryParent.value,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setData(initialData);
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setData(initialData);
        setShowLoading(false);
        fetchData();
        return setSuccess('La subcategoría ha sido creada exitosamente.');
      } else {
        setData(initialData);
        setShowLoading(false);
        return setError('La creación de la subcategoría ha fallado.');
      }
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Agregar
      </AccordionSummary>
      <AccordionDetails>
        <Paper elevation={0} variant='outlined' style={{ padding: 20 }}>
          <div>Categoría</div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 10,
              alignItems: 'center',
              marginTop: 10,
            }}>
            <TextField
              id='category'
              label='Categoría'
              value={data.category}
              onChange={handleChange}
              autoComplete='off'
            />
            <Button
              variant='contained'
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: '#ffffff',
                minWidth: 'unset',
                width: 35,
                height: 35,
                borderRadius: '50%',
              }}
              onClick={handleSubmitCategory}>
              <AddIcon fontSize='small' />
            </Button>
          </div>
        </Paper>

        <Paper
          elevation={0}
          variant='outlined'
          style={{ padding: 20, marginTop: 20 }}>
          <div>Subcategoría</div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 10,
              alignItems: 'center',
              marginTop: 10,
            }}>
            <Autocomplete
              id='categoryParent'
              options={categoryOptions}
              value={data.categoryParent}
              onChange={(event, newValue) =>
                setData({ ...data, categoryParent: newValue })
              }
              renderInput={(params) => (
                <TextField {...params} label='Categoría' />
              )}
              isOptionEqualToValue={(option, selected) =>
                option.value == selected.value
              }
            />
            <TextField
              id='subcategory'
              label='Subcategoría'
              value={data.subcategory}
              onChange={handleChange}
              autoComplete='off'
            />
            <Button
              variant='contained'
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: '#ffffff',
                minWidth: 'unset',
                width: 35,
                height: 35,
                borderRadius: '50%',
              }}
              onClick={handleSubmitSubcategory}>
              <AddIcon fontSize='small' />
            </Button>
          </div>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddCategories;
