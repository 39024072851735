import { useEffect, useState, useContext } from 'react';
import { restGet } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';

const Analytics = () => {
  const { setError, setShowLoading } = useContext(Context);

  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'browser',
      headerName: 'Navegador',
    },
    {
      field: 'os',
      headerName: 'Sistema Operativo',
    },
    {
      field: 'ip',
      headerName: 'Dirección IP',
    },
    {
      field: 'user_agent',
      headerName: 'Usuario',
      flex: 2,
    },
    {
      field: 'datetime',
      headerName: 'Fecha',
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'visitors_logs';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('La operación ha fallado.');
      }

      if (response.ok) {
        setRows(response.data);
      }

      setShowLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Analíticas </h2>
      <Grid columns={columns} rows={rows} disableAddMenu={true} />
    </div>
  );
};

export default Analytics;
