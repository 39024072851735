import axios from 'axios';
import { logout } from '../utils/helpers';

const rest = (method = 'get', url = '', params = {}, callback = false) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  params.token = sessionStorage.getItem('token');

  let axiosParams = {
    method: method,
    url: apiBaseUrl + url,
  };

  let config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
    },
  };

  if (method == 'get') {
    axiosParams.params = params;
  } else {
    axiosParams.data = params;
  }

  axios(axiosParams, config)
    .then((response) => {
      if (callback !== false) {
        return callback(response.data);
      }
    })
    .catch((error) => {
      if (
        error.response?.data?.data == 'Token expired' ||
        error.response?.data?.data == 'Token not valid'
      ) {
        logout();
        return;
      }

      let response = {
        exception: true,
        error: error,
      };

      if (callback !== false) {
        return callback(response);
      }
    });
};

export const restGet = (url, params, callback) =>
  rest('get', url, params, callback);
export const restPost = (url, params, callback) =>
  rest('post', url, params, callback);
