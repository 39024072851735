import { useState } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Sidebar = () => {
  const theme = useTheme();

  const [menu, setMenu] = useState([
    {
      name: 'Locaciones',
      path: '/locations',
      active: false,
      hovered: false,
      isGranted: true,
    },
    {
      name: 'Categorías',
      path: '/categories',
      active: false,
      hovered: false,
      isGranted: sessionStorage.getItem('root') == '1' ? true : false,
    },
    {
      name: 'Usuarios',
      path: '/users',
      active: false,
      hovered: false,
      isGranted: sessionStorage.getItem('admin') == '1' ? true : false,
    },
    {
      name: 'Analíticas',
      path: '/analytics',
      active: false,
      hovered: false,
      isGranted: sessionStorage.getItem('root') == '1' ? true : false,
    },
    {
      name: 'Generador QR',
      path: '/generator',
      active: false,
      hovered: false,
      isGranted: sessionStorage.getItem('root') == '1' ? true : false,
    },
  ]);

  const setActiveItem = (itemName) => {
    let menuRef = menu.map((item) => {
      item.active = false;

      if (item.name == itemName) {
        item.active = true;
      }

      return item;
    });

    setMenu(menuRef);
  };

  const handleHover = (index, isHovered) => {
    let updatedMenu = [...menu];
    updatedMenu[index].hovered = isHovered;
    setMenu(updatedMenu);
  };

  return (
    <List
      sx={{
        padding: 0,
        width: '20vw',
        height: '90vh',
        backgroundColor: '#595D89',
      }}>
      {menu.map(
        (each, index) =>
          each.isGranted && (
            <ListItem key={index} onClick={() => setActiveItem(each.name)}>
              <Link
                to={each.path}
                style={{
                  textDecoration: 'none',
                  color: each.hovered ? theme.palette.primary.main : '#ffffff',
                  width: '100%',
                }}
                onMouseEnter={() => handleHover(index, true)}
                onMouseLeave={() => handleHover(index, false)}>
                <ListItemText primary={each.name} />
              </Link>
            </ListItem>
          )
      )}
    </List>
  );
};

export default Sidebar;
